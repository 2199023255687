<template>
  <div class="tile is-ancestor is-parent full-quote-page">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <div class="columns is-gapless">
              <div class="column">
                <p class="title">Labour</p>
                <subtitle-totals :labels="['Labour Total', 'Grand Total']"
                  :totals="[labourTotal, innerValue.totalExGst]"
                  :shows="[true, true]" />
              </div>
              <div class="column">
                <div class="control is-pulled-right">
                  <div v-if="!audanetLock"
                    class="field-body">
                    <div class="field mr-2">
                      <div class="buttons">
                        <button v-if="value.subQuoteNo > 0 && !innerValue.readOnly && !readOnlyView"
                          class="button is-success tooltip is-tooltip-topright"
                          data-tooltip="Adjust Main Quote Items"
                          @click="toggleAvailableItemsMain()"
                          :disabled="innerValue.readOnly || readOnlyView">
                          <span class="icon">
                            <i class="mdi"
                              :class="{ 'mdi-adjust' : !isAvailableItemsMainActive, 'mdi-close' : isAvailableItemsMainActive }" />
                          </span>
                          <span>{{ isAvailableItemsMainActive ? 'Done' : 'Adjust' }}</span>
                        </button>
                        <button v-if="!innerValue.readOnly && !readOnlyView"
                          class="button is-primary tooltip is-tooltip-topright"
                          data-tooltip="Add new labour"
                          @click="toggleAvailableItems()"
                          :disabled="innerValue.readOnly || readOnlyView">
                          <span class="icon">
                            <i class="mdi"
                              :class="{ 'mdi-plus' : !isAvailableItemsActive, 'mdi-close' : isAvailableItemsActive }" />
                          </span>
                          <span>{{ isAvailableItemsActive ? 'Close' : 'Add' }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="field">
                      <div v-if="!innerValue.readOnly && !readOnlyView"
                        class="dropdown is-right tooltip is-tooltip-topright"
                        :class="{ 'is-active': addBlankDropdownActive }"
                        @mouseleave="addBlankDropdownActive = false"
                        data-tooltip="Add blank items">
                        <div class="dropdown-trigger">
                          <button class="button is-primary"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu-addblank"
                            @click="toggleAddBlankMenu">
                            <span>
                              <i class="mdi mdi-shape-square-plus"
                                aria-hidden="true" /></span>
                            <span class="icon is-small">
                              <i class="mdi mdi-chevron-down mdi-24px"
                                aria-hidden="true" />
                            </span>
                          </button>
                        </div>
                        <div class="dropdown-menu"
                          id="dropdown-addblank"
                          role="menu">
                          <div class="dropdown-content">
                            <a href="#"
                              class="dropdown-item"
                              @click="addBlankItem(itemCategoryTypes.RR)">
                              R &amp; R
                            </a>
                            <a href="#"
                              class="dropdown-item"
                              @click="addBlankItem(itemCategoryTypes.RWA)">
                              RWA
                            </a>
                            <a href="#"
                              class="dropdown-item"
                              @click="addBlankItem(itemCategoryTypes.REP)">
                              Repair
                            </a>
                            <a href="#"
                              class="dropdown-item"
                              @click="addBlankItem(itemCategoryTypes.PAINT)">
                              Paint
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="audanetLock && !$showSpinnerRequestCount">
                    <audanet-lock-notification />
                  </div>
                </div>
              </div>
            </div>
            <available-labours v-if="vehicle && isAvailableItemsActive"
              v-model="innerValue.labours"
              :quote-id="value.quoteId"
              :next-line-number="nextLineNumber"
              :rr-rate="getLabourRate(itemCategoryTypes.RR, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :rwa-rate="getLabourRate(itemCategoryTypes.RWA, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :repair-rate="getLabourRate(itemCategoryTypes.REP, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :paint-rate="getLabourRate(vehicle.paintGroup, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :shop-rate="value.shopRate"
              :vehicle-paint-group="vehicle.paintGroup"
              :quoting-method="value.quotingMethod"
              :schedule-id="vehicle.scheduleId"
              :body-id="vehicle.bodyId"
              :make="vehicle.model.make"
              :model="vehicle.model.model"
              :year="vehicleBuildYear"
              :labour-type="value.labourType"
              :is-ntar="isNtar"
              @item-added="onItemAdded" />
            <available-labours-main v-if="vehicle && isAvailableItemsMainActive"
              v-model="innerValue.labours"
              :quote-id="value.quoteId"
              :next-line-number="nextLineNumber"
              :quote-no="value.quoteNo"
              :sub-quote-no="value.subQuoteNo"
              :rr-rate="getLabourRate(itemCategoryTypes.RR, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :rwa-rate="getLabourRate(itemCategoryTypes.RWA, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :repair-rate="getLabourRate(itemCategoryTypes.REP, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :paint-rate="getLabourRate(vehicle.paintGroup, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
              :vehicle-paint-group="vehicle.paintGroup"
              :quoting-method="value.quotingMethod"
              :schedule-id="vehicle.scheduleId"
              :body-id="vehicle.bodyId"
              :make="vehicle.model.make"
              :model="vehicle.model.model"
              :year="vehicleBuildYear"
              :labour-type="innerValue.labourType"
              @item-added="onItemAdded" />
            <div v-if="isAvailableItemsActive || isAvailableItemsMainActive"
              class="is-divider" />
            <bulma-table v-if="vehicle"
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :draggable="true"
              :show-pagination="false"
              @pageChanged="onPageChange"
              @sort="sort">
              <draggable v-model="innerValue.labours"
                v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
                :tag="'tbody'"
                @change="itemMoved"
                @end="endMove"
                handle=".item-drag-handle">
                <tr v-for="(item, index) in activeItems"
                  :key="item.quoteItemId"
                  :class="[defaultRowColourClass(item), rowDisplayStatus(item, getItemAssessments(item))]"
                  @click="highlightSelected(index, $event)"
                  :data-itemid="item.quoteItemId">
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle has-text-right linenumber-input">
                    <vue-numeric v-if="(!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView) && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.lineNumber"
                      :precision="0"
                      separator="" />
                    <span v-else>{{ item.lineNumber }}</span>
                  </td>
                  <td class="has-vertical-middle itemno-input">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item No."
                      v-model="item.itemNo"
                      @input="setItemStatus(item)">
                    <span v-else>{{ item.itemNo }}</span>
                  </td>
                  <td :class="['has-vertical-middle itemdesc-input',defaultCellColourClass(item,'itemDesc')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item Description"
                      v-focus-inserted="{ focus: $userInfo.scrollItemLabour }"
                      v-model="item.itemDesc"
                      @input="setItemStatus(item)">
                    <span v-else>{{ item.itemDesc }}</span>
                    <div v-if="item.itemComment"
                      class="help is-primary">
                      <span class="has-text-weight-bold">Comment: </span>
                      <span>{{ item.itemComment }}</span>
                    </div>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                      <input v-model="item.blend"
                        type="checkbox"
                        @change="updateBlend(index, item)">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                    <span v-else
                      class="icon has-text-info">
                      <i class="mdi mdi-24px"
                        :class="[ item.blend ? 'mdi-check' : '' ]" />
                    </span>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                      <input v-model="item.reportOnly"
                        type="checkbox"
                        @change="setItemStatus(item)">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                    <span v-else
                      class="icon has-text-info">
                      <i class="mdi mdi-24px"
                        :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
                    </span>
                  </td>
                  <td v-if="innerValue.quotingMethod === quotingMethodTypes.Hour"
                    class="has-vertical-middle has-text-right money-input">
                    <vue-numeric v-if="!innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="[ defaultRowColourClass(item), item.rate !== getLabourRateEx(item).rate ? 'is-warning' : '' ]"
                      v-model.number="item.rate"
                      :precision="2"
                      @value-changed="updateValue(index, item)" />
                    <span v-else>{{ item.rate | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td :class="['has-vertical-middle has-text-right money-input',defaultCellColourClass(item,'hourValue')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'hourValue')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'hourValue')">
                    <vue-numeric v-if="!innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.hourValue"
                      :minus="true"
                      :precision="2"
                      @blur="updateValue(index, item)" />
                    <span v-else>{{ item.hourValue | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td :class="['has-vertical-middle has-text-right',defaultCellColourClass(item,'dollarValue')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'dollarValue')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'dollarValue')">
                    <div class="p-1">{{ item.dollarValue | formatNumber($userInfo.locale) }}</div>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <span class="item-type-tag"
                      :class="`tag is-${item.itemType.toLowerCase()}`">{{ item.itemType }}</span>
                  </td>
                  <td v-if="isNtar"
                    class="has-vertical-middle has-text-right is-content-width">
                    <div v-if="!innerValue.readOnly && !readOnlyView && !audanetLock"
                      v-show="!item.isLoadItem && item.itemType !== itemCategoryTypes.RR && item.itemType !== itemCategoryTypes.PAINT"
                      class="select">
                      <select v-model="item.loadingPercent"
                        :class="defaultRowColourClass(item)"
                        @change="changeLoadingPercent(item)">
                        <option :value="0">0%</option>
                        <option :value="10">10%</option>
                        <option :value="25">25%</option>
                      </select>
                    </div>
                    <span v-else>{{ item.loadingPercent | formatPercentage($userInfo.locale, 0) }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="item.presentAs">
                      <div v-for="split in item.presentAs"
                        :key="split.itemType">
                        <div class="tags has-addons mb-0 present-as-tags">
                          <span :class="`tag mb-1 is-${split.itemType.toLowerCase()}`">{{ split.itemType }}</span>
                          <span class="tag is-dark mb-1">{{ split.percent }}%</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle itemstatus-input">
                    <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input is-capitalized"
                      v-model="item.itemStatus">
                    <span v-else>{{ item.itemStatus }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <div class="icon-container items-center">
                      <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
                        :item="item"
                        :item-assessments="getItemAssessments(item)"
                        :next-assessment-number="nextSupplemetryNo"
                        :quoting-method="innerValue.quotingMethod"
                        :icon-size="4"
                        class="icon" />

                      <button v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === false || item.deleted === false)"
                        class="button is-danger is-small is-inverted tooltip"
                        @click="deleteItem(item, index)"
                        :disabled="innerValue.readOnly || readOnlyView || isItemRemoved(item, getItemAssessments(item))"
                        data-tooltip="Delete">
                        <span class="icon is-small">
                          <i class="mdi mdi-delete mdi-24px" />
                        </span>
                      </button>
                      <!-- TODO: need to save original itemStatus to be able to restore -->
                      <!-- <button v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === true || item.deleted === true)"
                        class="button is-primary-dark is-small is-inverted tooltip"
                        @click="restoreItem(item, index)"
                        :disabled="innerValue.readOnly || readOnlyView || isItemRemoved(item, getItemAssessments(item))"
                        data-tooltip="Restore">
                        <span
                          class="icon is-medium">
                          <i class="mdi mdi-undo mdi-24px" />
                        </span>
                      </button> -->
                      <button class="button is-primary-dark is-small is-inverted tooltip"
                        @click="showPresentAsModal(item, index)"
                        :disabled="isItemRemoved(item, getItemAssessments(item))"
                        data-tooltip="Present As">
                        <span class="icon is-small">
                          <i class="mdi mdi-format-page-split mdi-24px" />
                        </span>
                      </button>
                      <button class="button is-primary is-small is-inverted item-drag-handle pr-0 pl-1"
                        :disabled="innerValue.readOnly || readOnlyView">
                        <span class="icon is-small">
                          <i class="mdi mdi-drag-vertical mdi-24px" />
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
              <template slot="footer">
                <tr>
                  <th class="has-text-right"
                    :colspan="totalColSpan">R&amp;R</th>
                  <th class="has-text-right"
                    :class="{ 'has-text-danger' : rrTotal < 0 }">{{ rrTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="5" />
                </tr>
                <tr>
                  <th class="has-text-right"
                    :colspan="totalColSpan">Repair</th>
                  <th class="has-text-right"
                    :class="{ 'has-text-danger' : repairTotal < 0 }">{{ repairTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="5" />
                </tr>
                <tr>
                  <th class="has-text-right"
                    :colspan="totalColSpan">RWA</th>
                  <th class="has-text-right"
                    :class="{ 'has-text-danger' : rwaTotal < 0 }">{{ rwaTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="5" />
                </tr>
                <tr>
                  <th class="has-text-right"
                    :colspan="totalColSpan">Paint</th>
                  <th class="has-text-right"
                    :class="{ 'has-text-danger' : paintTotal < 0 }">{{ paintTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="5" />
                </tr>
                <tr>
                  <th class="has-text-right has-text-success"
                    :colspan="totalColSpan">Totals</th>
                  <th class="has-text-right"
                    :class="[ labourTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ labourTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="100%" />
                </tr>
              </template>
            </bulma-table>
            <totals-floating-widget :active.sync="isFloatingWidgetActive"
              title="Labour Totals"
              :rr-total="rrTotal"
              :repair-total="repairTotal"
              :rwa-total="rwaTotal"
              :paint-total="paintTotal"
              :totals="labourTotal"
              :total-ex-gst="innerValue.totalExGst" />
          </div>
        </div>
      </article>
    </div>
    <quote-item-present-as-modal v-if="isPresentAsModalActive"
      :active.sync="isPresentAsModalActive"
      v-model="selectedItem.presentAs"
      @ok="closeModal()"
      @cancel="closeModal()"
      @close="closeModal()">
      <p slot="text-title">Present As</p>
    </quote-item-present-as-modal>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { LabourColumns } from './columns'
import { ItemCategoryTypes, QuotingMethodTypes, LabourTimeTypes, QuoteItemStatusTypes, EventHubTypes } from '@/enums'
import _orderBy from 'lodash/orderBy'
import VueNumeric from '@/components/VueNumeric'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { TotalsFloatingWidget, AvailableLabours, AvailableLaboursMain, SupplementaryIcon } from './components'
import draggable from 'vuedraggable'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import { QuoteAssessmentMixin, QuoteItemDifferenceMixin, QuoteItemValidationMixin, QuoteLabourMixin } from './mixins'
import QuoteService from './QuoteService'
import Guid from '@/components/Guid'
import { QuoteItemModel } from '@/classes/viewmodels'
import SubtitleTotals from '@/components/SubtitleTotals'
import { FocusInserted } from '@/components/directives'
// import * as bulmaToast from 'bulma-toast'
import { QuoteItemPresentAsModal } from '@/components/BulmaModal'

export default {
  name: 'QuoteLabour',
  directives: { FocusInserted },
  components: {
    BulmaTable,
    VueNumeric,
    TotalsFloatingWidget,
    draggable,
    AvailableLabours,
    AvailableLaboursMain,
    SupplementaryIcon,
    SubtitleTotals,
    QuoteItemPresentAsModal
  },
  mixins: [NumberFiltersMixin, QuoteItemValidationMixin, QuoteLabourMixin, QuoteItemDifferenceMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    vehicle: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null,
      // items: null,
      selectedRow: null,
      isTableLoading: false,
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 100
      },
      isFloatingWidgetActive: true,
      isAvailableItemsActive: false,
      isAvailableItemsMainActive: false,
      addBlankDropdownActive: false,
      isPresentAsModalActive: false,
      selectedItem: null,
      itemsAssessments: []
    }
  },
  computed: {
    columns() {
      const c = _cloneDeep(LabourColumns)
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        c[6].title = 'Amount'
        c.splice(5, 1)
      } else if (this.value.quotingMethod === QuotingMethodTypes.Hour) {
        c[6].title = LabourColumns[6].title
      }
      if (!this.isNtar) {
        c.splice(c.length - 4, 1)
      }
      c[1].isVisible = this.$userInfo.isSupportUser
      c[c.length - 2].isVisible = this.$userInfo.isSupportUser
      return c
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    activeItems() {
      return this.items.filter((i) => this.isItemDeleted(i, this.getItemAssessments(i)) === false)
    },
    totalRows() {
      return this.activeItems.length
    },
    items() {
      if (this.innerValue) {
        return this.innerValue.labours
      }
    },
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    },
    totalColSpan() {
      let length = this.columns.length
      length = length - (this.isNtar ? 8 : 7)
      if (this.$userInfo.isSupportUser) {
        length++
      }
      return length
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    vehicleBuildYear() {
      const buildYear = this.vehicle.buildYear ? Number(this.vehicle.buildYear) : this.vehicle.model.startYear ? Number(this.vehicle.model.startYear) : 0
      return buildYear
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.value.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  watch: {
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.innerValue = newVal
    //     this.filter.sortColumn = ''
    //     this.filter.sortOrder = ''
    //     // this.items = _orderBy(this.innerValue.labours.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    //   },
    //   deep: true
    // },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
    // items: {
    //   handler: function(newVal, oldVal) {
    //     //
    //   },
    //   deep: true
    // }
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.innerValue = this.value
    //     this.items = _orderBy(this.innerValue.labours.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    //   },
    //   deep: true
    // }
  },
  created() {
    // this.$eventHub.$on(EventHubTypes.EntityReload, () => {
    //   this.onReload()
    // })
    this.innerValue = this.value
    this.getLabourItemsAssessments()
    // this.items = _orderBy(this.innerValue.labours.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
  },
  updated() {},
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = this.value
    })
    this.$v.entity.labours.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    onPageChange(pageIndex) {},
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      console.log(name, order)
      this.innerValue.labours = _orderBy(
        this.innerValue.labours.filter((i) => !i.deleted),
        [this.filter.sortColumn, 'itemDesc'],
        [this.filter.sortOrder]
      )
      let sortNo = Math.min(...this.innerValue.labours.map((i) => i.sortNo))
      this.innerValue.labours.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    updateTotal(index, item) {
      this.isFloatingWidgetActive = true
    },
    updateBlend(index, item) {
      const isBlend = item.blend
      const itemToUpdate = this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId)
      if (item.itemType === ItemCategoryTypes.PAINT && this.isNtar) {
        const value = isBlend ? roundAwayFromZero(item.hourValue * 0.7) : roundAwayFromZero(item.hourValue / 0.7)
        if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
          itemToUpdate.hourValue = value
          itemToUpdate.dollarValue = item.hourValue
        } else {
          itemToUpdate.hourValue = value
          itemToUpdate.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
        }
        this.updateTotal()
      }
      // if (this.items[index].itemType === ItemCategoryTypes.PAINT && this.isNtar) {
      //   const value = isBlend ? roundAwayFromZero(this.items[index].hourValue * 0.7) : roundAwayFromZero(this.items[index].hourValue / 0.7)
      //   if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
      //     this.items[index].hourValue = value
      //     this.items[index].dollarValue = this.items[index].hourValue
      //   } else {
      //     this.items[index].hourValue = value
      //     this.items[index].dollarValue = roundAwayFromZero(this.items[index].hourValue * this.items[index].rate)
      //   }
      //   this.updateTotal()
      // }
    },
    deleteItem(item, index) {
      let originalItemStatus = item.itemStatus
      this.deletePaintLoading(item)
      const deletedItem = _cloneDeep(item)
      if (item.isNew) {
        const itemIndex = this.innerValue.labours
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.labours.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.addUpdateRepairPaintLoading(deletedItem)
      this.updateTotal()
      // bulmaToast.toast({
      //   message: 'Item deleted',
      //   type: 'is-danger',
      //   dismissible: true,
      //   position: 'top-center',
      //   animate: { in: 'fadeIn', out: 'fadeOut' }
      // })
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.labours.splice(this.innerValue.labours.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    restoreItem(item, index) {
      item.deleted = false
      item.isDeleted = false
      //TODO: must set item status to original item status
      this.updateTotal()
    },
    deletePaintLoading(item) {
      if (this.isNtar && Guid.validGuid(item.linkedItemId)) {
        const linkedItem = this.innerValue.labours.find((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        const index = this.innerValue.labours.findIndex((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        console.log(linkedItem)
        if (linkedItem) {
          this.deleteItem(linkedItem, index)
        }
      }
    },
    toggleAvailableItems() {
      this.isAvailableItemsMainActive = false
      if (this.value.labourType) {
        this.isAvailableItemsActive = !this.isAvailableItemsActive
      } else {
        this.$toast.open({
          message: 'Please select a labour type from detail page',
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
      }
    },
    toggleAvailableItemsMain() {
      this.isAvailableItemsActive = false
      this.isAvailableItemsMainActive = !this.isAvailableItemsMainActive
    },
    onItemAdded(newItems) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      if (!_isEmpty(newItems)) {
        const vm = this
        newItems.forEach(function (item) {
          vm.innerValue.labours.splice(vm.innerValue.labours.length, 1, item)
          if (vm.isNtar && item.hourValue > 0 && (item.itemType === ItemCategoryTypes.REP || item.itemType === ItemCategoryTypes.PAINT)) {
            vm.addUpdateRepairPaintLoading(item)
          }
        })
        this.innerValue.lines = Math.max(...newItems.map((i) => i.lineNumber), 0) + 1
        // this.items = _orderBy(this.innerValue.labours.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
        this.$toast.open({
          message: `Item${newItems.length > 1 ? 's' : ''} added`,
          type: 'is-success',
          position: 'is-bottom',
          queue: false
        })
      }
    },
    generateItem(itemNo, description, hourValue, rate, type) {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, itemNo, description, type)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = itemNo ? itemNo : this.$filters.pad(newItem.lineNumber, 4)
      newItem.hourValue = this.innerValue.quotingMethod === QuotingMethodTypes.Hour ? hourValue : roundAwayFromZero(hourValue * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hourValue * rate)
      newItem.opgCode = this.vehicle.paintGroup
      newItem.sortNo = this.innerValue.labours.length ? Math.max(...this.innerValue.labours.map((i) => i.sortNo)) + 1 : 1
      return newItem
    },
    toggleAddBlankMenu() {
      this.addBlankDropdownActive = !this.addBlankDropdownActive
    },
    addBlankItem(type) {
      //  :rr-rate="getLabourRate(itemCategoryTypes.RR).rate"
      // :rwa-rate="getLabourRate(itemCategoryTypes.RWA).rate"
      // :repair-rate="getLabourRate(itemCategoryTypes.REP).rate"
      // :paint-rate="getLabourRate(vehicle.paintGroup).rate"
      let hour = 0
      let rate = 0
      // const type = ItemCategoryTypes.RR
      switch (type) {
        case ItemCategoryTypes.RR:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(ItemCategoryTypes.RR, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
        case ItemCategoryTypes.RWA:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(ItemCategoryTypes.RWA, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
        case ItemCategoryTypes.REP:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(ItemCategoryTypes.REP, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
        case ItemCategoryTypes.PAINT:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(this.vehicle.paintGroup, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
      }
      const newItem = this.generateItem('', '', hour, rate, type)
      this.innerValue.labours.splice(this.innerValue.labours.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    setItemStatus(item) {
      // item.itemStatus = 'C'
    },
    changeLoadingPercent(item) {
      // console.log('changeLoadingPercent')
      this.addUpdateRepairPaintLoading(item, true)
    },
    async getDefaultPaintItem(itemNo) {
      const filter = {
        countryId: Guid.empty(),
        companyId: this.value.companyId,
        query: itemNo,
        butterflyCode: '',
        scheduleId: this.vehicle.scheduleId || '',
        bodyId: this.vehicle.bodyId || '',
        bodySize: '',
        paintGroup: this.vehicle.paintGroup || '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 1
      }
      const paintItem = await QuoteService.getAvailableLabourItems(filter, this.value.labourType.toLowerCase())
      return paintItem
    },

    itemMoved(event) {
      // console.log(event.moved)
      let sortNo = Math.min(...this.innerValue.labours.map((i) => i.sortNo))
      this.innerValue.labours.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    endMove(event) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
    },
    closeModal() {
      this.isPresentAsModalActive = false
    },
    showPresentAsModal(item, index) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.isPresentAsModalActive = true
      })
    },
    getLabourItemsAssessments() {
      this.itemsAssessments = this.getItemsAssessments(this.items, this.innerValue.quoteAssessments)
    },
    getItemAssessments(item) {
      const itemAssessments = this.itemsAssessments.find((i) => i.quoteItemId === item.quoteItemId)
      if (itemAssessments) {
        return itemAssessments.assessments
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.linenumber-input {
  width: 60px;
}
.itemno-input {
  max-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 6.5em;
}
.itemstatus-input {
  width: 50px;
}
tfoot {
  tr {
    th {
      &:not(:last-child) {
        border-right: none;
      }
    }
  }
  tr:not(:last-child) {
    th {
      border-top: none;
      border-bottom: none;
    }
  }
}
.item-type-tag {
  width: 52px;
}
.present-as-tags {
  display: inline-flex;
  .tag {
    font-size: 0.6rem !important;
  }
}
</style>
